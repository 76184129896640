
import { PropType, defineComponent } from 'vue';
import { swalNotification } from '@/core/helpers/utils';
import { IVatTuBienBan } from '@/core/interfaces/ApiResponses';
import { formatCurrencyAndQuantity } from '@/core/helpers/utils';
import CurrencyInputCustom from '@/components/forms-elements/CurrencyInputCustom.vue';

export default defineComponent({
  name: 'vat-tu-bien-ban',

  components: { CurrencyInputCustom },

  emits: ['on-change', 'on-change-phuong-thuc', 'change-don-gia'],

  props: {
    allowEditingDonGia: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    forPhieuNhapLe: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    vatTuItems: {
      type: Array as PropType<Array<IVatTuBienBan>>,
      default: () => ([]),
    },
  },

  data() {
    return {
      items: this.vatTuItems.filter(item => item.so_luong_trong_bien_ban > 0).map(item => ({...item, so_luong_trong_bien_ban: 0})),
      soLuongVatTuBanDau: this.vatTuItems.filter(item => item.so_luong_trong_bien_ban >= 0).map(item => item.so_luong_trong_bien_ban),
    }
  },

  watch: {
    vatTuItems: {
      handler(value) {
        this.items = [...this.vatTuItems];
      },
      deep: true,
      immediate: true,
    }
  },

  computed: {
    vatTuItemsDisplay() {
      return this.items.filter(item => item.so_luong_trong_bien_ban > 0);
    }
  },

  methods: {
    formatCurrencyAndQuantity,

    soLuongHD(vatTu: IVatTuBienBan) {
      return vatTu.so_luong_trong_bien_ban;
    },

    async onChangedSoLuongHandler(vatTu: IVatTuBienBan, index: number, value: number) {
      const previousValue = this.soLuongVatTuBanDau[index];

      if (+value < 0) {
        await swalNotification(
          'Phải lớn hơn hoặc bằng 0',
          'error',
        );

        this.items[index].so_luong_trong_bien_ban = previousValue;

        this.$emit('on-change', { items: this.items });

        return;
      }

      if (+this.items[index].so_luong_can < +value) {
        await swalNotification(
          'Vượt quá số lượng vật tư cần',
          'error',
        );

        this.items[index].so_luong_trong_bien_ban = +this.items[index].so_luong_can;

        this.$emit('on-change', { items: this.items });

        return;
      }

      this.$emit('on-change', { items: this.items });
    },

    onChangedPhuongThucHandler(index: number, value: string) {
      this.$emit('on-change-phuong-thuc', {
        index, value
      });
    },

    handleDonGiaUpdate({
      value,
      index,
    }: {
      value: number,
      index: number,
    }) {
      this.$emit('change-don-gia', {
        value,
        index,
      });
    }
  }
})
